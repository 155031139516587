import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Discount extends BaseModel {
  endpoint = process.env.VUE_APP_API_URL
  // provinces = 'provinces'
  // regencies = 'regencies'
  // districts = 'districts'
  // villages = 'villages'

  list ( type,params = {} ) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.endpoint + type)
      const urlParams = new URLSearchParams(url.search)
      Object.keys(params).forEach(key => {
        if (typeof params[key] === 'string' || typeof params[key] === 'number') {
          urlParams.append(key, params[key])
        }
      })
      axios.get(this.endpoint + type + '?' + urlParams.toString()).then(response => {
        resolve(typeof params.only_count !== 'undefined' ? response.data : {
          data: response.data.data,
          current_page: response.data.data,
          total: response.data.total
        })
      }).catch(error => {
        reject(error)
      })
    })
  }

  find( type, id, params = {}) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.endpoint + type)
      const urlParams = new URLSearchParams(url.search)
      Object.keys(params).forEach(key => {
        if (typeof params[key] === 'string' || typeof params[key] === 'number') {
          urlParams.append(key, params[key])
        }
      })
      const getUrl = this.endpoint + type + '/' + id + (Object.keys(params).length ? '?' + urlParams.toString() : '')
      axios.get(getUrl).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}