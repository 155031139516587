import BaseModel from '@/models/BaseModel.js'

export default class ProductType extends BaseModel {
    fields =  {
      'name': {
        type: 'text',
        label: 'nama',
        rules: 'required'
      },
      'parent_id': {
        label: 'Paket Induk',
        type: 'enum',
        options: {}
      },
      'sort':  {
        label: 'Urutan',
        type: 'number',
      },
      'date_range': {
        label: 'Tanggal Publis',
        type: 'dateRange',
      },
      'is_active': {
        label: 'Status',
        type: 'enum',
        options: {
          0: 'Tidak Aktif',
          1: 'Aktif'
        },
        rules: 'required'
      },
      'admin_wa_number': {
        label: 'Nomor Tanya Admin',
        type: 'number'
      },
      'description': {
        type: 'editor',
        label: 'Deskripsi',
        rules: null
      },
    }
    endpoint = process.env.VUE_APP_API_URL + 'package-categories'
}