import BaseModel from "@/models/BaseModel.js";

export default class Package extends BaseModel {
  fields = {
    name: {
      type: "text",
      label: "Nama Paket",
      rules: "required",
    },
    package_category_id: {
      type: "enum",
      label: "Paket Kategori",
      rules: "required",
      options: [],
    },
    regency_id: {
      type: "enum",
      label: "Kota",
      rules: "required",
      options: [],
    },
    type: {
      type: "enum",
      label: "Tipe",
      rules: "required",
      options: {
        regular: "REGULER",
        ibuluber: "IBULUBER",
        ibubaik: "IBUBAIK"
      },
    },
    // 'tags': {
    //   type: 'string',
    //   label: 'Tag',
    //   rules: 'required'
    // },
    price: {
      type: "number",
      label: "Harga",
      rules: "required",
    },
    normal_price: {
      type: "number",
      label: "Harga Normal",
      rules: "required",
    },
    seat_available: {
      type: "number",
      label: "Total Order",
      rules: "required",
    },
    total_day: {
      type: "number",
      label: "Lama Perjalanan",
      rules: "required",
    },
    seat: {
      type: "number",
      label: "Batas Kuota",
      rules: "required",
    },
    include_pilgrims: {
      type: "number",
      label: "Muatan Jama'ah",
    },
    estimation_departure_date: {
      type: "date",
      label: "Tanggal Keberangkatan",
      rules: "required",
    },
    discount: {
      type: "number",
      label: "Diskon",
      rules: "required",
    },
    min_dp: {
      type: "number",
      label: "Minimal DP",
      rules: "required",
    },
    is_active: {
      label: "Status",
      type: "enum",
      options: {
        0: "Tidak Aktif",
        1: "Aktif",
      },
      rules: "required",
    },
    qouta_reduction: {
      label: "Penjadwalan Pengurangan Kouta",
      type: "enum",
      options: {
        everyMinute: "everyMinute",
        everyTwoMinutes: "everyTwoMinutes",
        everyThreeMinutes: "everyThreeMinutes",
        everyFourMinutes: "everyFourMinutes",
        everyFiveMinutes: "everyFiveMinutes",
        everyTenMinutes: "everyTenMinutes",
        everyFifteenMinutes: "everyFifteenMinutes",
        everyThirtyMinutes: "everyThirtyMinutes",
        hourly: "hourly",
        "hourlyAt 10": "hourlyAt 10",
        "hourlyAt 20": "hourlyAt 20",
        "hourlyAt 30": "hourlyAt 30",
        "hourlyAt 40": "hourlyAt 40",
        "hourlyAt 50": "hourlyAt 50",
        everyTwoHours: "everyTwoHours",
        everyThreeHours: "everyThreeHours",
        everyFourHours: "everyFourHours",
        everySixHours: "everySixHours",
        daily: "daily",
        '': 'Tidak Ada'
      },
    },
    qouta_reduction_count: {
      label: "Jumlah Pengurangan Kouta",
      type: "number"
    },
    exclude: {
      type: "editor",
      label: "Exclude",
    },
    description: {
      type: "editor",
      label: "Deskripsi",
      rules: "required",
    },
  };
  endpoint = process.env.VUE_APP_API_URL + "packages";
}
