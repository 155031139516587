<template>
  <b-card header-tag="header" class="m-sm-5 p-sm-4 shadow">
    <template #header>
      <h5 class="mb-0">{{ $route.name }}</h5>
    </template>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <template v-for="(m, index) in model">
          <div v-if="m.label == 'Kota'" :key="index">
            <ValidationProvider
              tag="div"
              rules="required"
              v-slot="{ errors }"
              slim
            >
              <label class="form-label">Provinsi</label>
              <b-form-select
                id="provincy"
                ref="provincy"
                :class="[errors[0] ? 'is-invalid' : '']"
                v-model="provincies.value"
                :options="provincies.options"
              ></b-form-select>
              <span class="text-danger invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required"
              v-slot="{ errors }"
              slim
            >
              <div class="mt-3 mb-3">
                <label for="" class="form-label">Kota</label>
                <b-form-select
                  :class="[errors[0] ? 'is-invalid' : '']"
                  id="city"
                  :ref="index"
                  v-model="m.value"
                  :options="regencies.options"
                >
                </b-form-select>
                <span class="text-danger invalid-feedback">{{
                  errors[0]
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div v-else>
            <form-group-input
              :ref="index"
              :type="m.type"
              :label="typeof m.label === 'string' ? m.label : null"
              :options="getOptions(index)"
              :need-label="true"
              v-model="m.value"
              :rules="typeof m.rules !== 'undefined' ? m.rules : null"
              :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null"
              :id="index"
              @change="getOnChangeCategory(m)"
            />
          </div>
        </template>
        <div>
          <b-button
            :href="'#/master-data/Paket'"
            variant="warning"
            class="mt-3 text-white"
          >
            <font-awesome-icon
              icon="spinner"
              spin
              v-if="isLoading"
            ></font-awesome-icon>
            Batal
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            class="mt-3 text-white float-right"
          >
            <font-awesome-icon
              icon="spinner"
              spin
              v-if="isLoading"
            ></font-awesome-icon
            >Simpan
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import {
  saveProcess,
  setModelValue,
  scrollToError,
  objectToOptions,
} from "@/_helpers";
import formGroupInput from "@/components/FormGroupInput.vue";
import Package from "@/models/Package.js";
import ProductType from "@/models/ProductType.js";
import Address from "@/models/Address.js";

const PackagePackageModel = new Package();
const ProductTypeModel = new ProductType();
const AddressModel = new Address();

export default {
  components: {
    formGroupInput,
  },
  watch: {
    "$route.params.id": function() {
      if (this.mode === "update") {
        this.getDetail();
      }
    },
    "provincies.value": function(val) {
      this.getCity(val);
    },
  },
  computed: {
    mode: function() {
      return this.$route.params.id !== undefined ? "update" : "create";
    },
  },
  data() {
    return {
      isLoading: false,
      model: PackagePackageModel.init(),
      provincies: {
        value: "",
        options: null,
      },
      regencies: {
        value: null,
        options: null,
      },
    };
  },
  mounted() {
    this.getProvincy();
    this.getCategoryProduct();
    if (this.mode === "update") {
      this.getDetail();
    }
  },
  methods: {
    getOptions(key) {
      return typeof this.model[key].options !== "undefined"
        ? objectToOptions(this.model[key].options)
        : null;
    },
    getCity(id) {
      // console.log(id)
      AddressModel.list("regencies", {
        province_id: id,
        limit: 10000,
      }).then((res) => {
        let data = [];
        data = [];
        res.data.forEach((d) => {
          data.push({ value: d.id, text: d.name });
        });
        this.regencies.options = data;
      });
    },
    getOnChangeCategory (data) {
      if (data.label === 'Paket Kategori') {
        console.log('test')
      }
    },
    getProvincy() {
      AddressModel.list("provinces", {
        limit: 1000,
      }).then((res) => {
        let data = [];
        res.data.forEach((d) => {
          data.push({ value: d.id, text: d.name });
        });
        this.provincies.options = data;
      });
    },
    getCategoryProduct() {
      ProductTypeModel.list({
        limit: 10000,
      }).then((res) => {
        let data = [];
        data = [];
        res.data.forEach((d) => {
          data[d.id] = d.name;
        });
        this.model.package_category_id.options = data;
      });
    },
    getDetail() {
      PackagePackageModel.find(this.$route.params.id).then(resp => {
        this.estimation_departure_date = resp.estimation_departure_date  
        setModelValue(this.model, resp)
        this.getProvincyBy(resp.regency.province_id)
        console.log(this.$refs.estimation_departure_date[0].setValue(this.model.estimation_departure_date.value))
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
      })
    },
    async getProvincyBy(id) {
      const data = await AddressModel.list("provinces", { id: id });
      this.provincies.value = await data.data[0].id;
    },
    resetForm() {
      this.$nextTick(() => {
        // clearModelValue(this.model)
        Object.keys(this.model).forEach((key) => {
          this.$refs[key][0].setValue(null);
        });
        this.$refs.form.reset();
      });
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form);
          return false;
        }
        saveProcess(this.model, this.mode === "update")
          .then((model) => {
            console.log(this.model);
            this.isLoading = true;
            if (this.mode === "update") {
              PackagePackageModel.update(this.$route.params.id, model)
                .then(() => {
                  this.isLoading = false;
                  this.$store.dispatch(
                    "notification/success",
                    "Data Paket berhasil diubah."
                  );
                  this.$router.push(
                    "/master-data/Paket/" + this.$route.params.id
                  );
                })
                .catch((error) => {
                  this.isLoading = false;
                  this.$store.dispatch("notification/error", error);
                });
            } else {
              PackagePackageModel.create(model)
                .then((resp) => {
                  this.isLoading = false;
                  // this.resetForm()
                  this.$store.dispatch(
                    "notification/success",
                    "Data Paket berhasil disimpan."
                  );
                  this.$router.push("/master-data/Paket/" + resp.id);
                })
                .catch((error) => {
                  this.isLoading = false;
                  this.$store.dispatch("notification/error", error);
                });
            }
          })
          .catch(() => {});
      });
    },
  },
};
</script>
